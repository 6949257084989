import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="w-full pt-8 sm:pt-32 pb-8">
      <div className="text-center">
        <h1 className="text-2xl mb-2">NOT FOUND</h1>
        <p>お探しのページは一時的にアクセスができない状況にあるか、<br />移動もしくは削除された可能性があります。</p>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
